import React, { Component } from "react";
import { Menu } from "antd";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import ViewCompactRoundedIcon from "@material-ui/icons/ViewCompactRounded";

const { SubMenu } = Menu;

export default class TopMenu extends Component {
  state = {
    current: "mail",
  };

  handleClick = (e) => {
    console.log("click ", e);
    this.setState({
      current: e.key,
    });
  };
  render() {
    return (
      <div>
        <Menu
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          mode="horizontal"
        >
          <Menu.Item key="home">
            <HomeRoundedIcon />
            Home
          </Menu.Item>
          <Menu.Item key="about">
            <InfoRoundedIcon />
            About Me
          </Menu.Item>

          <Menu.Item key="portfolio">
            <ViewCompactRoundedIcon />
            Portfolio
          </Menu.Item>

          <Menu.Item key="contact">
            <ViewCompactRoundedIcon />
            Contact Me
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}
