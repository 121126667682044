import React from "react";
import Header from "../../Components/Header";
import { Button } from "antd";
import Acmilan from "../../Pages/Portfolio/Acmilan";
import Luizamora from "../Portfolio/Luizamora";
import Godo from "../Portfolio/Godo";
import Classbellkart from "../Portfolio/Classbellkart";
import Primeshopee from "../Portfolio/Primeshopee";
import Foodfm from "../Portfolio/Foodfm";
import Foodfmapp from "../Portfolio/Foodfmapp";
import Godoweb from "../Portfolio/Godoweb";
import Footer from "../../Components/Footer";

const Home = () => {
  return (
    <div>
      <Header />
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>
                Hello I am Fasir
                <br />
                UX/UI <span>Designer </span>| Front-end <span>Developer</span>
              </h1>
              <p>
                I understand the perfect user interface should look good, and
                work even better. Alongside my clients, I uncover problems and
                solve them. In short, I create bolder online experiences.
              </p>

              <a
                href="https://firebasestorage.googleapis.com/v0/b/fasir-k.appspot.com/o/Resume%2Ffasir.pdf?alt=media&token=b832c4d6-b76a-4d2d-b981-4a4cc8b1fd70"
                target="_blank"
              >
                <Button className="btn">Download My CV</Button>
              </a>
              <div className="social-icons">
                <a
                  href="https://www.linkedin.com/in/fasir-k-82784317a/"
                  target="_blank"
                >
                  <img src={require("../../Images/linkedin.png")} />
                </a>
                <a
                  href="https://www.behance.net/fasirkunnac257"
                  target="_blank"
                >
                  <img src={require("../../Images/behance.png")} />
                </a>
                {/* <a href="https://www.facebook.com/fasir.k" target="_blank">
                  <img src={require("../../Images/facebook.png")} />
                </a> */}
                <a href="https://twitter.com/FASIR24274226" target="_blank">
                  <img src={require("../../Images/twitter.png")} />
                </a>
                <a href="https://www.instagram.com/fasir_k/" target="_blank">
                  <img src={require("../../Images/instagram.png")} />
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <img src={require("../../Images/fasir.jpg")} />
            </div>
          </div>
        </div>
      </section>

      <section className="service">
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-center">
              <img src={require("../../Images/web.png")} />
              <h3>Web Apps & Corporate Sites</h3>
              <p>
                This is where problem solving meets visual impact. I’ll unite
                products and users, design and experiences.
              </p>
            </div>

            <div className="col-md-4 text-center">
              <img src={require("../../Images/app.png")} />
              <h3>Mobile App Design</h3>
              <p>
                Using iOS, Android and an expert vision, I’ll take your
                application to the next level.
              </p>
            </div>

            <div className="col-md-4 text-center">
              <img src={require("../../Images/development.png")} />
              <h3>Front End Development</h3>
              <p>
                I’ll help you to bridge the gap between design and development.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="portfolio ">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>RECENT WORKS</h2>
            </div>
            <div className="col-md-6 text-center">
              <Acmilan />
            </div>

            <div className="col-md-6 text-center">
              <Godo />
            </div>

            <div className="col-md-6 text-center">
              <Luizamora />
            </div>
            <div className="col-md-6 text-center">
              <Classbellkart />
            </div>
            <div className="col-md-6 text-center">
              <Foodfmapp />
            </div>
            <div className="col-md-6 text-center">
              <Primeshopee />
            </div>
            <div className="col-md-6 text-center">
              <Foodfm />
            </div>
            <div className="col-md-6 text-center">
              <Godoweb />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
