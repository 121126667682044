import React from "react";
import { Avatar } from "antd";
import TopMenu from "./TopMenu";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const Header = () => {
  return (
    <div>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Avatar size="default" src={require("../Images/profile.jpeg")} />
              <h3>Fasir </h3>
            </div>
            <div className="col-md-9 menu">
              {/* <TopMenu /> */}
              <p>
                <PhoneAndroidIcon />
                +91 9847 95 20 54
              </p>
              <p>
                <MailOutlineIcon />
                fasirkunnath@gmail.com
              </p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
