import React from "react";

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="social-icons">
                <a
                  href="https://www.linkedin.com/in/fasir-k-82784317a/"
                  target="_blank"
                >
                  <img src={require("../Images/linkedin.png")} />
                </a>
                <a
                  href="https://www.behance.net/fasirkunnac257"
                  target="_blank"
                >
                  <img src={require("../Images/behance.png")} />
                </a>
                <a href="https://www.facebook.com/fasir.k" target="_blank">
                  <img src={require("../Images/facebook.png")} />
                </a>
                <a href="https://twitter.com/FASIR24274226" target="_blank">
                  <img src={require("../Images/twitter.png")} />
                </a>
                <a href="https://www.instagram.com/fasir_k/" target="_blank">
                  <img src={require("../Images/instagram.png")} />
                </a>
              </div>
              <p>© 2020 All rights reserved</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
